import { definePayloadPlugin, definePayloadReducer, definePayloadReviver } from 'nuxt/app'
import { DataOrThrow } from '../utils/dataOrThrow'

export default definePayloadPlugin(() => {
  definePayloadReducer('DataOrThrow', (value) => {
    return value instanceof DataOrThrow && value.toJSON()
  })
  definePayloadReviver('DataOrThrow', (value) => {
    return DataOrThrow.fromJSON(value)
  })
})
