import type { IconAliases, IconSet, IconValue } from 'vuetify'
import { Icon } from '#components'

export const aliases: IconAliases = <IconAliases>{
  email: 'material-symbols:mail-rounded',
  collapse: 'material-symbols:arrow-drop-up-rounded',
  complete: 'material-symbols:check-circle-outline-rounded',
  cancel: 'material-symbols:cancel-rounded',
  close: 'material-symbols:close-rounded',
  delete: 'material-symbols:delete-outline-rounded',
  // delete (e.g. v-chip close)
  clear: 'material-symbols:close-rounded',
  success: 'material-symbols:check-circle-rounded',
  info: 'material-symbols:info-rounded',
  warning: 'material-symbols:warning-rounded',
  error: 'material-symbols:close-rounded',
  prev: 'material-symbols:chevron-left-rounded',
  next: 'material-symbols:chevron-right-rounded',
  checkboxOn: 'material-symbols:check-box-rounded',
  checkboxOff: 'material-symbols:check-box-outline',
  checkboxIndeterminate: 'material-symbols:indeterminate-check-box',
  delimiter: 'material-symbols:remove-rounded',
  // for carousel
  sortAsc: 'material-symbols:arrow-upward-rounded',
  sortDesc: 'material-symbols:arrow-downward-rounded',
  expand: 'material-symbols:arrow-drop-down-rounded',
  menu: 'material-symbols:menu-rounded',
  subgroup: 'material-symbols:menu-open-rounded',
  dropdown: 'material-symbols:arrow-drop-down-rounded',
  radioOn: 'material-symbols:radio-button-checked',
  radioOff: 'material-symbols:radio-button-unchecked',
  edit: 'material-symbols:edit-rounded',
  ratingEmpty: 'material-symbols:star-outline-rounded',
  ratingFull: 'material-symbols:star-rounded',
  ratingHalf: 'material-symbols:star-star-half-rounded',
  loading: 'material-symbols:cached-rounded',
  first: 'material-symbols:first-page-rounded',
  last: 'material-symbols:last-page-rounded',
  unfold: 'material-symbols:unfold-more-rounded',
  file: 'material-symbols:attach-file-rounded',
  plus: 'material-symbols:add',
  minus: 'material-symbols:remove-rounded',
  calendar: 'material-symbols:calendar-month-outline-rounded',
}

function iconValueToName(icon: IconValue): string {
  return aliases[icon as string] ?? icon
}

export const svgNuxtIcon: IconSet = { component: ({ icon, tag, ...rest }) => h(tag, rest, [h(Icon, { name: iconValueToName(icon), mode: 'svg', ...rest })]) }
export const cssNuxtIcon: IconSet = { component: ({ icon, tag, ...rest }) => h(tag, rest, [h(Icon, { name: iconValueToName(icon), mode: 'css', ...rest })]) }
