import type { PostgrestSingleResponse } from '@supabase/supabase-js'

export default function dataOrThrow<X extends Array<C> | object, C = X extends Array<infer C> ? C : never>({ data, error, count, status, statusText }: PostgrestSingleResponse<X>): X & { count: PostgrestSingleResponse<X>['count'], status: PostgrestSingleResponse<X>['status'], statusText: PostgrestSingleResponse<X>['statusText'] } {
  if (error) {
    useLoadingIndicator().finish({ error: true })
    throw error
  }
  if (Array.isArray(data)) {
    return new DataOrThrow(count, status, statusText, ...data)
  }
  else if (data && typeof data === 'object') {
    Object.defineProperties(data, { count: { value: count, enumerable: false }, status: { value: status, enumerable: false }, statusText: { value: statusText, enumerable: false } })
  }
  return data
}

export class DataOrThrow<T> extends Array<T> implements Array<T> {
  constructor(
    readonly count: PostgrestSingleResponse<T>['count'],
    readonly status: PostgrestSingleResponse<T>['status'],
    readonly statusText: PostgrestSingleResponse<T>['statusText'],
    ...items: Array<T>
  ) {
    super(...items)
  }

  toJSON() {
    return JSON.stringify({ items: [...this], ...this })
  }

  static fromJSON(json: string) {
    const { count, status, statusText, items } = JSON.parse(json)
    return new DataOrThrow(count, status, statusText, ...items)
  }
}

export type dataExtractRow<X> = X extends Array<infer T> ? T : never
