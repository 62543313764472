import type { Directive } from 'vue'

function thisPlay(this: HTMLVideoElement) {
  return this.play()
}
function thisPause(this: HTMLVideoElement) {
  return this.pause()
}

export const vPlayOnHover = <Directive>{
  mounted: (el: HTMLVideoElement) => {
    el.addEventListener('pointerenter', thisPlay, { passive: true })
    el.addEventListener('pointerleave', thisPause, { passive: true })
  },
  unmounted: (el: HTMLVideoElement) => {
    el.removeEventListener('pointerenter', thisPlay)
    el.removeEventListener('pointerleave', thisPause)
  },
}

export default vPlayOnHover
